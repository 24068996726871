import { useEffect, useState } from "react";
import banner from "../banner.jpg";
import { Link } from "react-router-dom";
const Banner = () => {
  const [search, setSearch] = useState();
  const [find, setFind] = useState([]);
  const [word, setWord] = useState("");
  useEffect(() => {
    setSearch(["a", "b", "test", "mb"]);
  }, []);
  const findSearch = (e) => {
    setWord(e.target.value);
    const filteredCountries = search.filter((item) =>
      item.indexOf(e.target.value) > -1 ? item : null
    );
    e.target.value.length === 0 ? setFind([]) : setFind(filteredCountries);
  };
  const findResult = () => {
    if (find.length === 0 && word.length > 0) {
      return <div className="find-search">Not Found</div>;
    }
    if (find.length > 0) {
      return (
        <div className="find-search">
          {find.map((item) => {
            return (
              <Link key={item} to="#">
                {item}
              </Link>
            );
          })}
        </div>
      );
    }
  };
  return (
    <div
      className="banner d-flex align-items-center"
      style={{ backgroundImage: `url(${banner})` }}
    >
      <div className="bg-custom">
        <div className="container">
          {/* START */}
          <div className="row">
            <div className="col-lg-6 mx-auto">
              <div className="banner-area text-center pt-4 pb-4">
                {/* <p>
                  Gemstone Real Estate Ltd. proudly announcing a unique project
                  at Sher-e- Bangla Road, West Dhanmondhi, Dhaka Namely Gemstone
                  Shaheen Tower.
                </p> */}
                <h2 className="mt-2 mb-4 banner-title">
                  <strong>Gemstone Real Estate</strong>{" "}
                </h2>
                {/* <div className="search-area">
                  <input
                    value={word}
                    onChange={(e) => findSearch(e)}
                    type="text"
                    className="inp-search"
                    placeholder="Search"
                  />
                  <button className="btn-search m-2">Search All</button>
                </div> */}
                {findResult()}
              </div>
            </div>
          </div>
          {/* END */}
        </div>
      </div>
    </div>
  );
};

export default Banner;
